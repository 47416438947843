// --------------------------------------------------------------------
// Retrieve Font Size -------------------------------------------------
// Used in _mixins.scss [@mixin font-size] ----------------------------
// --------------------------------------------------------------------
@function get-font-size($size, $elem) {
	@return nth(map-get(map-get($font__scale, $elem), $size), 1);
}

// --------------------------------------------------------------------
// Retrieve Line Height -----------------------------------------------
// Used in _mixins.scss [@mixin font-size] ----------------------------
// --------------------------------------------------------------------
@function get-line-height($size, $elem) {
	@return nth(map-get(map-get($font__scale, $elem), $size), 2);
}

// --------------------------------------------------------------------
// Retrieve Kerning ---------------------------------------------------
// Used in _mixins.scss [@mixin font-size] ----------------------------
// --------------------------------------------------------------------
@function get-kerning($size, $elem) {
	@return nth(map-get(map-get($font__scale, $elem), $size), 3);
}

// --------------------------------------------------------------------
// Retrieve Font Family -----------------------------------------------
// Used in _mixins.scss [@mixin font-family] --------------------------
// --------------------------------------------------------------------
@function get-font-family($elem) {
	@return map-get($font__family, $elem);
}

// --------------------------------------------------------------------
// Retrieve Font Weight -----------------------------------------------
// Used in _mixins.scss [@mixin font-weight] --------------------------
// --------------------------------------------------------------------
@function get-font-weight($elem) {
	@return map-get($font__weight, $elem);
}

// --------------------------------------------------------------------
// Retrieve Padding of Content Area Elements --------------------------
// Used in _mixins.scss [@mixin font-size] ----------------------------
// --------------------------------------------------------------------
@function get-content-padding($elem) {
	@return map-get($content__padding, $elem);
}

// --------------------------------------------------------------------
// Retrieve Colors ----------------------------------------------------
// Usage: color(typography, 1) ----------------------------------------------
// --------------------------------------------------------------------
@function color($elem, $variant) {
	@return map-get(map-get($color, $elem), $variant);
}
