.lights-off .settings .features-box,
.settings .features-box {
    transform: perspective(1017px) rotateY(1deg) translateY(-4%);
    box-shadow: none;
}

.settings {
    .features-illustration:nth-child(1), 
    .features-illustration:nth-child(3) {
        top: -7%;
    }
    .features-illustration:nth-child(2), 
    .features-illustration:nth-child(4) {
        top: 1%;
        left: 58%;
    }
}

@include media('<medium') {
    .settings {
        .features-box {
            transform: none;
            margin-bottom: 1em;
        }

        .features-image {
            margin-bottom: 1em;
        }
    }
}