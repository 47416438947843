.download {
  ul {
    padding-left: 0;
  }
  li {
    margin: 0.2em;
    line-height: 1.65;
  }
}

.lights-off {
  a {
    .dlStyle {
      color: #fff;
    }
    p:hover {
      color: var(--accent-color);
    }
  }
  a:hover {
    text-decoration: none;
  }
}

.dlStyle {
  display: flex;
  font-size: smaller;
  line-height: 35px;

  p {
    display: flex;
    margin: 0 auto 0 6px;
  }

  p.fileSize {
    margin: 0;
  }

  img {
    margin-right: 0.5em;
  }
}
