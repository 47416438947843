.container,
.container-sm {
    width: 100%;
    margin: 0 auto;
    padding-left: get-content-padding(mobile);
    padding-right: get-content-padding(mobile);

    @include media('>small') {
        padding-left: get-content-padding(desktop);
        padding-right: get-content-padding(desktop);
    }
}

.container {
    max-width: $container__width + (get-content-padding(desktop) * 2);
}

.container-sm {
    max-width: $container__width-sm + (get-content-padding(desktop) * 2);
}

.container {

    .container-sm {
        max-width: $container__width-sm;
        padding-left: 0;
        padding-right: 0;
    }
}

// /* Text meant only for screen readers. */
// .screen-reader-text {
//     clip: rect(1px, 1px, 1px, 1px);
//     position: absolute !important;
//     height: 1px;
//     width: 1px;
//     overflow: hidden;
//     word-wrap: normal !important;
//     /* Many screen reader and browser combinations announce broken words as they would appear visually. */

//     &:focus {
//         border-radius: 2px;
//         box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
//         clip: auto !important;
//         display: block;

//         @if (get-font-family(heading) !=get-font-family(base)) {
//             @include font-family(heading);
//         }

//         @include font-size(8, mobile, true, false, true);

//         @if (get-font-size(8, desktop) !=get-font-size(8, mobile)) {
//             @include media('>medium') {
//                 @include font-size(8, desktop, true, false, true);
//             }
//         }

//         @include font-weight(semibold);
//         line-height: 16px;
//         text-decoration: none;
//         text-transform: uppercase;
//         background-color: color(bg, 1);
//         color: color(primary, 1) !important;
//         border: none;
//         height: auto;
//         left: 8px;
//         padding: 16px 32px;
//         top: 8px;
//         width: auto;
//         z-index: 100000;
//     }
// }

.list-reset {
    list-style: none;
    padding: 0;
}

.text-left {
    text-align: left;
}

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.text-primary {
    color: color(primary, 1);
}

.text-secondary {
    color: color(secondary, 1);
}

.has-top-divider {
    @include divider(before);
}

.has-bottom-divider {
    @include divider(after);
}

.m-0 {
    margin: 0;
}

.mt-0 {
    margin-top: 0;
}

.mr-0 {
    margin-right: 0;
}

.mb-0 {
    margin-bottom: 0;
}

.ml-0 {
    margin-left: 0;
}

.m-8 {
    margin: 8px;
}

.mt-8 {
    margin-top: 8px;
}

.mr-8 {
    margin-right: 8px;
}

.mb-8 {
    margin-bottom: 8px;
}

.ml-8 {
    margin-left: 8px;
}

.ml-4 {
    margin-left: 4px;
}

.m-16 {
    margin: 16px;
}

.mt-16 {
    margin-top: 16px;
}

.mr-16 {
    margin-right: 16px;
}

.mb-16 {
    margin-bottom: 16px;
}

.ml-16 {
    margin-left: 16px;
}

.m-24 {
    margin: 24px;
}

.mt-24 {
    margin-top: 24px;
}

.mr-24 {
    margin-right: 24px;
}

.mb-24 {
    margin-bottom: 24px;
}

.ml-24 {
    margin-left: 24px;
}

.m-32 {
    margin: 32px;
}

.mt-32 {
    margin-top: 32px;
}

.mr-32 {
    margin-right: 32px;
}

.mb-32 {
    margin-bottom: 32px;
}

.ml-32 {
    margin-left: 32px;
}

.m-40 {
    margin: 40px;
}

.mt-40 {
    margin-top: 40px;
}

.mr-40 {
    margin-right: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.ml-40 {
    margin-left: 40px;
}

.m-48 {
    margin: 48px;
}

.mt-48 {
    margin-top: 48px;
}

.mr-48 {
    margin-right: 48px;
}

.mb-48 {
    margin-bottom: 48px;
}

.ml-48 {
    margin-left: 48px;
}

.m-56 {
    margin: 56px;
}

.mt-56 {
    margin-top: 56px;
}

.mr-56 {
    margin-right: 56px;
}

.mb-56 {
    margin-bottom: 56px;
}

.ml-56 {
    margin-left: 56px;
}

.m-64 {
    margin: 64px;
}

.mt-64 {
    margin-top: 64px;
}

.mr-64 {
    margin-right: 64px;
}

.mb-64 {
    margin-bottom: 64px;
}

.ml-64 {
    margin-left: 64px;
}

.p-0 {
    padding: 0;
}

.pt-0 {
    padding-top: 0 !important;
}

.pr-0 {
    padding-right: 0;
}

.pb-0 {
    padding-bottom: 0;
}

.pl-0 {
    padding-left: 0;
}

.p-8 {
    padding: 8px;
}

.pt-8 {
    padding-top: 8px;
}

.pr-8 {
    padding-right: 8px;
}

.pb-8 {
    padding-bottom: 8px;
}

.pl-8 {
    padding-left: 8px;
}

.p-16 {
    padding: 16px;
}

.pt-16 {
    padding-top: 16px;
}

.pr-16 {
    padding-right: 16px;
}

.pb-16 {
    padding-bottom: 16px;
}

.pl-16 {
    padding-left: 16px;
}

.p-24 {
    padding: 24px;
}

.pt-24 {
    padding-top: 24px;
}

.pr-24 {
    padding-right: 24px;
}

.pb-24 {
    padding-bottom: 24px;
}

.pl-24 {
    padding-left: 24px;
}

.p-32 {
    padding: 32px;
}

.pt-32 {
    padding-top: 32px;
}

.pr-32 {
    padding-right: 32px;
}

.pb-32 {
    padding-bottom: 32px;
}

.pl-32 {
    padding-left: 32px;
}

.p-40 {
    padding: 40px;
}

.pt-40 {
    padding-top: 40px;
}

.pr-40 {
    padding-right: 40px;
}

.pb-40 {
    padding-bottom: 40px;
}

.pl-40 {
    padding-left: 40px;
}

.p-48 {
    padding: 48px;
}

.pt-48 {
    padding-top: 48px;
}

.pr-48 {
    padding-right: 48px;
}

.pb-48 {
    padding-bottom: 48px;
}

.pl-48 {
    padding-left: 48px;
}

.p-56 {
    padding: 56px;
}

.pt-56 {
    padding-top: 56px;
}

.pr-56 {
    padding-right: 56px;
}

.pb-56 {
    padding-bottom: 56px;
}

.pl-56 {
    padding-left: 56px;
}

.p-64 {
    padding: 64px;
}

.pt-64 {
    padding-top: 64px;
}

.pr-64 {
    padding-right: 64px;
}

.pb-64 {
    padding-bottom: 64px;
}

.pl-64 {
    padding-left: 64px;
}

/* Reveal animations */
.sr {

    .has-animations {

        .is-revealing {
            visibility: hidden;
        }
    }
}