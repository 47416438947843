.button {
    display: inline-flex;
	@if ( get-font-family(heading) != get-font-family(base) ) {
		@include font-family(heading);
	}
    @include font-size(8, mobile, true, false, true);
    @if ( get-font-size(8, desktop) != get-font-size(8, mobile) ) {
        @include media( '>medium' ) {
            @include font-size(8, desktop, true, false, true);
        }
    }
    @include font-weight(semibold);
    line-height: 16px;
    text-decoration: none !important;
	text-transform: uppercase;
    background-color: color(bg, 3);
    color: color(primary, 1) !important;
    border: none;
    border-radius: 2px;
    cursor: pointer;
    justify-content: center;
    padding: 16px 32px;
	height: 48px;
    text-align: center;
    white-space: nowrap;

	&:hover {
		background: lighten(color(bg, 3), 1%);
	}

    &:active {
        outline: 0;
    }

    &::before {
        border-radius: 2px;
    }
}

.button-shadow {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        box-shadow: 0 8px 24px rgba(color(bg, 1), .25);
		mix-blend-mode: multiply;
        transition: box-shadow .15s ease;
    }

    &:hover {

        &::before {
            box-shadow: 0 8px 24px rgba(color(bg, 1), .35);
        }
    }
}

.button-sm {
    padding: 8px 24px;
	height: 32px;

    &.button-shadow {

        &::before {
            box-shadow: 0 4px 16px rgba(color(bg, 1), .25);
        }

        &:hover {

            &::before {
                box-shadow: 0 4px 16px rgba(color(bg, 1), .35);
            }
        }
    }
}

.button-primary {
	color: color(typography, 1i) !important;
	background-color: color(primary, 1);

    &:hover {
        background: lighten(color(primary, 1), 1%);
    }

    &.button-shadow {

        &::before {
            box-shadow: 0 8px 16px rgba(color(primary, 1), .25);
			mix-blend-mode: normal;
        }

        &:hover {

            &::before {
                box-shadow: 0 8px 16px rgba(color(primary, 1), .35);
            }
        }
    }

    .button-sm {

        &.button-shadow {

            &::before {
                box-shadow: 0 4px 16px rgba(color(primary, 1), .25);
            }

            &:hover {

                &::before {
                    box-shadow: 0 4px 16px rgba(color(primary, 1), .35);
                }
            }
        }
    }
}

.button-download {
	color: color(typography, 1i) !important;
	background-color: color(download, 1);

    &:hover {
        background: color(download, 2);
    }

    &.button-shadow {

        &::before {
            box-shadow: 0 8px 16px rgba(color(primary, 1), .25);
			mix-blend-mode: normal;
        }

        &:hover {

            &::before {
                box-shadow: 0 8px 16px rgba(color(primary, 1), .35);
            }
        }
    }

    .button-sm {

        &.button-shadow {

            &::before {
                box-shadow: 0 4px 16px rgba(color(primary, 1), .25);
            }

            &:hover {

                &::before {
                    box-shadow: 0 4px 16px rgba(color(primary, 1), .35);
                }
            }
        }
    }
}

.button-block {
    display: flex;
}
