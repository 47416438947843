.hero {
  text-align: center;
  padding-top: 48px;
  padding-bottom: 88px;
}

.hero-copy {
  position: relative;
  /* To display elements above hero illustrations */
}

.hero-paragraph {
  margin-bottom: 32px;
}

.hero-cta {
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 80px;
}

.lights-off {
  .lights-toggle {
    color: rgb(199, 199, 199);
  }
}

.lights-toggle {
  color: rgba(color(typography, 2), 0.65);
}

.header-illustration {
  display: none;

  img,
  svg {
    max-width: none;
  }
}

.header-illustration-image {
  display: none;
}

.hero-media {
  position: relative;
  left: -5%;
}

.hero-media-illustration {
  position: absolute;
  top: -10%;
  left: -13px;

  body.lights-off & {
    left: 38px;
  }

  img,
  svg {
    max-width: 136%;
  }
}

.hero-media-container {
  position: relative;
}

.hero-media-image {
  margin: 0 auto;
  height: 38rem;
  box-shadow: 48px 16px 48px rgba(24, 37, 56, 0.12);
  transform: perspective(1800px) rotateY(-6deg) rotateX(2deg) translatex(6%);

  .lights-off & {
    box-shadow: 48px 16px 48px rgba(darken(color(bg, 1i), 35%), 0.2);
  }
}

@include media("<medium") {
  .hero-cta {
    > * {
      display: flex;

      + * {
        margin-top: 32px;
      }
    }
  }

  .lights-toggle {
    justify-content: center;
  }

  .hero-media-illustration-image,
  .features-illustration {
    display: none !important;
  }

  .hero-media-container {
    width: 100vw;
  }

  .hero-media-container {
    .hero-media-image {
      transform: none;
      width: 355px;
      height: auto;
    }
  }
}

@include media(">ipad") {
  .hero {
    text-align: left;
    padding-top: 119px;
    padding-bottom: 120px;
  }

  .hero-inner {
    /* Split hero in two half */
    display: flex;
    justify-content: space-between;
  }

  .hero-copy {
    padding-top: 16px; 
    padding-right: 48px;
    min-width: 448px;
    max-width: 512px;
    z-index: 1;
  }

  .hero-title {
    margin-bottom: 16px;
  }

  .hero-paragraph {
    margin-bottom: 32px;
  }

  .hero-cta {
    display: flex;
    align-items: center;
    margin: 0;

    .button {
      min-width: 170px;

      &:first-child {
        margin-right: 32px;
      }
    }
  }

  .header-illustration {
    display: block;

    body.lights-off & {
      position: relative;
      left: 10%;
    }
  }

  .hero-media {
    z-index: 0;

    img,
    svg {
      max-width: none;
    }
  }

  .header-illustration-image {
    display: block;
    position: absolute;
    top: -168px;
    left: -720px;
    width: 1440px;
    height: 324px;
  }

  .header-logo-image {
    position: absolute;
  }
}
