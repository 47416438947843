.batch {
  .features-illustration {
    top: -6%;
  }
  .features-illustration:nth-child(2),
  .features-illustration:nth-child(4) {
    top: 11%;
  }
  .features-box {
    transform: perspective(1000px) rotateY(1deg) translateY(-1%);
  }
}
