.site-footer {
  @include font-size(8, mobile, true, true, true);

  @if (get-font-size(8, desktop) !=get-font-size(8, mobile)) {
    @include media(">ipad") {
      @include font-size(8, desktop, true, true, true);
    }
  }

  background: color(bg, 1i);
  color: color(typography, 2);

  a {
    @include anchor-aspect(footer);
    color: color(typography, 2i) !important;
  }

  &::before {
    display: none;

    .lights-off & {
      display: block;
    }
  }
}

div.footer-copyright:nth-child(3) {
  margin-bottom: 8px;
}

.site-footer-inner {
  position: relative;
  /* To display all elements above the background color */
  display: flex;
  flex-wrap: wrap;
  //padding-top: 48px;
  padding-bottom: 48px;
}

.footer-brand,
.footer-links,
.footer-social-links,
.footer-copyright {
  flex: none;
  width: 100%;
  display: inline-flex;
  justify-content: center;
  fill: #fff;
}

.footer-logo {
  height: 85px;
  width: 100%;
}

.footer-links,
.footer-social-links {
  li {
    + li {
      margin-left: 16px;
    }
  }
}

.footer-social-links {
  li {
    display: inline-flex;

    a {
      padding: 8px;

      &:hover {
        text-decoration: none;
        color: #f0e8e8;
      }
    }

    img,
    svg {
      display: inline;
      margin-right: 5px;
    }
  }
}

.footer-copyright a:not(:first-child,:last-child) {
    padding: 0 2px;
}

@include media(">ipad") {
  .site-footer-inner {
    justify-content: space-between;
    padding-top: 72px;
    padding-bottom: 72px;
  }

  .footer-brand,
  .footer-links,
  .footer-social-links,
  .footer-copyright {
    flex: 50%;
  }

  .footer-brand,
  .footer-copyright {
    justify-content: flex-start;
  }

  .footer-links,
  .footer-social-links {
    justify-content: flex-end;
  }

  .footer-links {
    order: 1;
    margin-bottom: 0;
  }
}

@include media("<=ipad") {
  .site-footer {
    padding: 2em;
  }

  .footer-social-links {
    margin-top: 1em;

    li {
      display: grid;
    }
  }
}
