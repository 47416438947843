.is-boxed {
  background: color(bg, 3);
}

.body-wrap {
  background: color(bg, 1);
  overflow: hidden;
  /* Sticky footer */
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.boxed-container {
  max-width: 1440px;
  margin: 0 auto;
  @include shadow;
}

main {
  flex: 1 0 auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.border.asset-dark {
  border: 1px solid #3a3a3a;
}

.border.asset-light {
  border: 1px solid #dbd7ca;
}

.shadow {
  box-shadow: 25px 24px 39px rgba(0, 0, 0, 0.2);
}
.asset-light.shadow{
  box-shadow: 5px 2px 10px rgba(0,0,0,0.4);
}

.item {
  flex: 0 0 50%;
  max-width: 50%;

  .features-box {
    transform: none;
    height: 100%;
  }
}

.itemx4 {
  flex: 0 0 20%;
  max-width: 20%;
}

@include media("<medium") {
  .item {
    flex: 0 0 100% !important;
    max-width: 100% !important;
  }
}

.center {
  margin-right: auto;
  margin-left: auto;
  text-align: center;
  display: block;
}

.tex {
  background: url(/assets/images/noisy-texture-100x100-o2-d16-c-ededed-t1.png)
    repeat;
}

.lights-off .tex {
  background: url(/assets/images/noisy-texture-100x100-o2-d18-c-182538-t1.png)
    repeat;
}

.lights-off .feature-title {
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
}

.section-inner {
  position: relative;
  /* To always display inner elements above pseudo decorative stuff */
  padding-top: 48px;
  padding-bottom: 48px;
}

@include media(">ipad") {
  .section-inner {
    padding-top: 88px;
    padding-bottom: 88px;
  }
}

#uSettingsDark,
#uSettingsLight {
  height: fit-content;
  margin-top: 4%;
}
