.why {
    margin: 7em 0;

    .item:hover {
        box-shadow: 0 5px 10px 0 rgba(150, 150, 150, 0.16);
    }

    .item {
        background-color: #fff;
        border: 1px solid #f5f5f5;
        flex: 0 0 23%;
        max-width: 23%;
        box-shadow: 0 0 10px 0 rgba(154, 154, 154, .16);
        margin: 0 10px 0 0;

        body.lights-off & {
            background-color: #28282b;
            border: 1px solid #373737
        }

        .inner {
            padding: 1em;
        }

        h3 {
            text-align: center;
            margin-bottom: 23px;
        }

        p {
            font-size: smaller;
            margin-bottom: 0;
        }

        .icon {
            width: 85px;
            height: 85px;
            border-radius: 50%;
            margin: 0 auto;
            position: relative;
            text-align: center;
            padding: 13px;
        }

        .icon.color1,
        .icon.icon.color2,
        .icon.icon.color3,
        .icon.icon.color4 {
            background-color: color(tertiary, 3);
            border: 8px solid #fbf0e6;

            body.lights-off & {
                border-color: #736b63
            }
        }

        .icon.icon.color2 {
            background-color: color(download, 2);
        }

        .icon.icon.color3 {
            background-color: #8950e4;
        }

        .icon.icon.color4 {
            background-color: #e54f4f;
        }
    }
}

@include media('<medium') {
    .why {
        .item {
            margin-bottom: 8px;
        }
    }
}