html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    /* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see http://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
    box-sizing: inherit;
}

body {
    background: color(bg, 1);
    /* Fallback for when there is no custom background color defined. */
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

/* width */
::-webkit-scrollbar {
    width: .6em;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #444; 
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

hr {
    border: 0;
    @include divider();
    margin-top: 24px;
    margin-bottom: 24px;
}

ul,
ol {
    margin-top: 0;
    margin-bottom: 24px;
    padding-left: 24px;
}

ul {
    list-style: disc;
    list-style-position: inside;
}

ol {
    list-style: decimal;
}

li>ul,
li>ol {
    margin-bottom: 0;
}

dl {
    margin-top: 0;
    margin-bottom: 24px;
}

dt {
    @include font-weight(semibold);
}

dd {
    margin-left: 24px;
    margin-bottom: 24px;
}

img {
    height: auto;
    /* Make sure images are scaled correctly. */
    max-width: 100%;
    /* Adhere to container width. */
    vertical-align: middle;
}

figure {
    margin: 24px 0;
    /* Extra wide images within figure tags don't overflow the content area. */
}

figcaption {
    @include font-size(7, mobile, true, true);
    padding: 8px 0;
}

img,
svg {
    display: block;
}

@media screen and (max-width: 350px) {

    video {
        width: 100%;
    }
}