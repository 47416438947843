.tableStyle {
  &:hover:not(.thead):not(.nohover) {
    background: #eee;
  }

  h3 {
    font-size: 19px;
  }

  .itemx4,
  .itemx2 {
    border-bottom: 1px solid #eee;
    height: 39px;
    line-height: 39px;
  }

  .itemx2 {
    flex: 0.2;
    padding: 0.3rem 0.4rem;
    height: inherit;
    line-height: 1.7;

    &:nth-child(2) {
      flex: 1;
    }

    &:nth-child(1) > p {
      font-weight: 600;
    }

    h3 {
      font-size: 23px;
    }

    p {
      margin: 0;
    }

    mark {
      background: #ffffff38;
      padding: 0 6px;
    }
  }
}

.tableStyle.thead {
  > .itemx4,
  .itemx2 {
    border: none;
    border-bottom: 1px solid #eee;
    height: auto;
    line-height: 1;

    &:hover {
      background: inherit;
    }
  }
}

.lights-off .tableStyle {
  &:hover:not(.thead):not(.nohover) {
    background: #3a3a3ab6;
  }

  .itemx4,
  .itemx2 {
    border-bottom: 1px solid #3a3a3a;
  }
}

.tableStyle.languages {
  justify-content: flex-start;
}

@include media("<medium") {
  .tableStyle {
    h3 {
      font-size: small;
    }
  }

  .tableStyle.languages {
    .itemx4 {
      flex: 0 0 33%;
      max-width: 33%;
      height: auto;
    }
    .itemx2 {
      flex: 0 0 50%;
      max-width: 50%;
    }
  }
}
